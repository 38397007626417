<script>
export default {
  name: "site-header"
}
</script>

<template>
  <header class="site-header">
    
    <div class="container">
      <div class="site-header-inner">
        <div class="brand header-brand">
          <h1 class="m-0">
            <a href="#">
              <img src="@/assets/logo.png" height="60px" width="60px" alt="Dereva logo">
            </a>
          </h1>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="less">

</style>